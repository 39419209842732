<template>
  <StoryblokComponent v-if="story" :blok="story.content" :data-page="url" />
</template>

<script setup>
import { usePageStore } from "@/stores/page";

definePageMeta({
  middleware: ['check-country']
});

const { slug } = useRoute().params;
const url = slug && slug.length > 0 ? slug.join("/") : "index";

const isPreview = useRuntimeConfig().public.CMS_ENV !== "production";
const resolve_relations = ["article-slider.articles", "page-slider.pages", "hero-article.article"];

const pageStore = usePageStore();

const story = await useAsyncStoryblok(`${url}`, {
  version: isPreview ? "draft" : "published",
  resolve_relations,
});

pageStore.$patch({
  page: story.value,
});

if (!isPreview) {
  if (!story.value) {
    console.error("[...slug].vue: ", url, JSON.stringify(story.value, null, 2));
    showError({ statusCode: 404, statusMessage: "Page Not Found" });
  }
}

if (story.value && story.value.content.seo) {
  useSeoTags(story.value.content.seo, story.value);
}

onMounted(() => {
  if (isPreview && story.value && story.value.id) {
    useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory), {
      resolveRelations: resolve_relations,
    });
  }
});
</script>
